import React from "react";
import Page from "../components/page";
import About from "../components/about/about";

export default function Home() {
  return (
     <Page>
       <About />
     </Page>
  );
}
